@font-face {
  font-family : "NanumSquareRoundOTF";
  font-style : extrabold;
  font-weight : 800;
  src: url(./assets/fonts/nanum-square-round/NanumSquareRoundOTFEB.woff) format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 900;
	font-display: swap;
	src: local('Pretendard Black'), url(./assets/fonts/Pretendard/woff2/Pretendard-Black.woff2) format('woff2'), url(./assets/fonts/Pretendard/woff/Pretendard-Black.woff) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 800;
	font-display: swap;
	src: local('Pretendard ExtraBold'), url(./assets/fonts/Pretendard/woff2/Pretendard-ExtraBold.woff2) format('woff2'), url(./assets/fonts/Pretendard/woff/Pretendard-ExtraBold.woff) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url(./assets/fonts/Pretendard/woff2/Pretendard-Bold.woff2) format('woff2'), url(./assets/fonts/Pretendard/woff/Pretendard-Bold.woff) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
	font-display: swap;
	src: local('Pretendard SemiBold'), url(./assets/fonts/Pretendard/woff2/Pretendard-SemiBold.woff2) format('woff2'), url(./assets/fonts/Pretendard/woff/Pretendard-SemiBold.woff) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url(./assets/fonts/Pretendard/woff2/Pretendard-Medium.woff2) format('woff2'), url(./assets/fonts/Pretendard/woff/Pretendard-Medium.woff) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url(./assets/fonts/Pretendard/woff2/Pretendard-Regular.woff2) format('woff2'), url(./assets/fonts/Pretendard/woff/Pretendard-Regular.woff) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 300;
	font-display: swap;
	src: local('Pretendard Light'), url(./assets/fonts/Pretendard/woff2/Pretendard-Light.woff2) format('woff2'), url(./assets/fonts/Pretendard/woff/Pretendard-Light.woff) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 200;
	font-display: swap;
	src: local('Pretendard ExtraLight'), url(./assets/fonts/Pretendard/woff2/Pretendard-ExtraLight.woff2) format('woff2'), url(./assets/fonts/Pretendard/woff/Pretendard-ExtraLight.woff) format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 100;
	font-display: swap;
	src: local('Pretendard Thin'), url(./assets/fonts/Pretendard/woff2/Pretendard-Thin.woff2) format('woff2'), url(./assets/fonts/Pretendard/woff/Pretendard-Thin.woff) format('woff');
}

@font-face {
	font-family: 'GosanjaOTF';
	src: url(./assets/fonts/Gosanja.woff) format('woff');
	font-display: swap;
}