.App {
  text-align: center;
  font-family: 'Pretendard';
  font-size: 18px;

  /* 스크롤 숨기기 */
  -ms-overflow-style: none;

  /* 텍스트 선택 금지 */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* 인터넷익스플로러 */
  user-select: none;
}

::-webkit-scrollbar {
  display: none;
}
